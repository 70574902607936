import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import parse from "html-react-parser";
import Title from "../../title/Title";
import "./LepoItem.scss";
import {
  displayIgnoredSpecial,
  hideTimeForVenues,
  ignoreSpecial,
  isGrossesHaus,
  PraterStudioVenues,
  specialProductionRoutes,
  venues,
} from "../../../i18n/route-utils";
import moment from "moment";
import {
  customInfoMapping,
  isNewFormat,
  replaceBacktick,
} from "../../../utilities";
import { useApolloClient } from "@apollo/client";
import IntText from "../../atoms/intTexts/IntTexts";

const SurtitleVariants = {
  "dt./ engl. ÜT": "de-en",
  "dt./engl. ÜT": "de-en",
  "dt./frz./engl. ÜT": "de-en-fr",
  "dt./frz. Sprache • dt./frz./engl. ÜT": "lang-de-fr-sur-de-en-fr",
  "engl. ÜT": "en",
  Audiodeskription: "audio",
  "engl. ÜT • Audiodeskription": "audio-and-en",
};

const LepoItem = ({ data, mainVenueId, linkRoute }) => {
  const [director, setDirector] = useState();
  const [writer, setWriter] = useState();
  const [title, setTitle] = useState();
  const { locale } = useIntl();

  const baseUrl = useMemo(() => {
    if (linkRoute) return linkRoute;

    for (let route of specialProductionRoutes) {
      if (data.eventDetails.title.toLowerCase().startsWith(route)) {
        return route;
      }
    }

    return "repertoire";
  }, [data, linkRoute]);

  useEffect(() => {
    if (data && data.eventDetails) {
      if (!data.eventDetailOverrides.authorAndDirector) {
        let wrtr = null;
        let dir = null;

        let dirNames = data.eventDetails.eventDetailsDirector
          ? getLastNames(data.eventDetails.eventDetailsDirector)
          : null;

        const writerNames = data.eventDetails.eventDetailsWriter
          ? getLastNames(data.eventDetails.eventDetailsWriter)
          : null;

        dir = dirNames;
        wrtr = writerNames;

        if (writerNames && dirNames && writerNames !== dirNames) {
          if (
            dirNames.length > writerNames.length &&
            dirNames.includes(writerNames)
          ) {
            wrtr = null;
          } else if (
            dirNames.length < writerNames.length &&
            writerNames.includes(dirNames)
          ) {
            dir = null;
          }
        }

        if (dir === wrtr) {
          wrtr = null;
        }

        setDirector(dir);
        setWriter(wrtr);
      }

      let title;

      if (isNewFormat(data.eventDetails.startTime)) {
        title = data.eventDetails.originalTitle || data.eventDetails.title;
      } else {
        title = data.eventDetails.title;

        if (
          data.eventDetails.title.startsWith("*") ||
          data.eventDetails.title.startsWith("#")
        ) {
          title = data.eventDetails.eventTitle;
        }

        if (
          data.eventDetails.seriesPart &&
          data.eventDetails.note &&
          data.eventDetails.note.length > 0
        ) {
          title = data.eventDetails.title.startsWith("#")
            ? data.eventDetails.note
            : `${data.eventDetails.title} &bull; ${data.eventDetails.note}`;
        }
      }

      setTitle(title);
    }
  }, [data]);

  const getLastNames = (string) => {
    if (
      string.includes(" von ") ||
      string.startsWith("von ") ||
      string.startsWith("Von ")
    ) {
      return string;
    }

    let newString = string
      .replace(/,\s*$/, "")
      .replace(" und ", ",")
      .split(",");

    let lastNames = newString.map((person) => {
      let names = person.split(/[\s]/);
      return names[names.length - 1];
    });

    return lastNames.join(", ");
  };

  const showVenue = () => {
    const id = Number(data.eventDetails.stageId);
    if (id === 147) return true;

    if (Number(mainVenueId) === 117) {
      return !!!isGrossesHaus.includes(id);
    } else {
      return !!(id !== Number(mainVenueId));
    }
  };

  return (
    <div
      key={`${data.eventDetails.startTime}-${data.eventDetails.stageId}`}
      className={`LepoItem ${
        data.eventDetails.customInfo &&
        !ignoreSpecial.includes(data.eventDetails.customInfo)
          ? "special"
          : ""
      }`}
      role="cell"
    >
      <div className="text">
        {title && (
          <Title
            key={data.databaseId}
            title={title}
            links={data.parentLinks}
            id={
              data.eventDetails.eventTitle
                ? null
                : data.eventDetails.productionId
            }
            activityId={
              data.eventDetails.activityId
                ? String(data.eventDetails.activityId)
                : null
            }
            isSeries={data.eventDetails.seriesPart}
            eventId={`id_${data.databaseId}_id`}
            baseRoute={baseUrl}
          >
            <h3>
              {!hideTimeForVenues.includes(
                String(data.eventDetails.stageId)
              ) ? (
                <>
                  <time className="screenreader">
                    {moment
                      .utc(data.eventDetails.startTime)
                      .format("DD.MM.YYYY HH:mm")}
                  </time>{" "}
                  <time aria-hidden>
                    {moment.utc(data.eventDetails.startTime).format("HH:mm")}
                  </time>{" "}
                  <span role="separator">
                    <span aria-hidden>&bull;&nbsp;</span>
                  </span>
                </>
              ) : (
                <>
                  <time className="screenreader">
                    {moment
                      .utc(data.eventDetails.startTime)
                      .format("DD.MM.YYYY")}
                  </time>
                </>
              )}
              <span className="screenreader">
                {
                  <FormattedMessage
                    id={`venue.${venues[data.eventDetails.stageId]}`}
                    defaultMessage={venues[data.eventDetails.stageId]}
                  />
                }
                {": "}
              </span>
              {mainVenueId !== undefined && showVenue() && (
                <span className="venueInfo" aria-hidden>
                  {PraterStudioVenues.includes(
                    Number(data.eventDetails.stageId)
                  ) ? (
                    <FormattedMessage
                      id="venue.prater-studios"
                      defaultMessage="Prater Studios"
                    />
                  ) : (
                    <FormattedMessage
                      id={`venue.${venues[data.eventDetails.stageId]}`}
                      defaultMessage={venues[data.eventDetails.stageId]}
                    />
                  )}
                  <span role="separator">
                    {" "}
                    <span aria-hidden>&bull;&nbsp;</span>
                  </span>
                </span>
              )}
              {replaceBacktick(parse(title))}
            </h3>
            <div className="otherInfo">
              {data.eventDetails.productionTitleAddition &&
                data.eventDetails.productionTitleAddition.length > 0 && (
                  <span className="subtitle divide">
                    {" "}
                    <span role="separator">
                      <span aria-hidden>&bull;&nbsp;</span>
                    </span>
                    {parse(data.eventDetails.productionTitleAddition)}
                  </span>
                )}
              {data.eventDetails.subtitle &&
                data.eventDetails.subtitle.length > 0 &&
                data.programmeInfo.calendarSubtitle && (
                  <span className="subtitle divide">
                    {" "}
                    <span role="separator">
                      <span aria-hidden>&bull;&nbsp;</span>
                    </span>
                    {parse(data.eventDetails.subtitle)}
                  </span>
                )}
              {data.eventDetails.noteLong &&
                !isNewFormat(data.eventDetails.startTime) &&
                data.eventDetails.noteLong.length > 0 && (
                  <span className="subtitle noteLong divide">
                    {" "}
                    <span role="separator">
                      <span aria-hidden>&bull;&nbsp;</span>
                    </span>
                    {parse(data.eventDetails.noteLong.replace(/^(-\s)/gm, ""))}
                  </span>
                )}
              {data.programmeInfo.lepoInfo &&
                data.programmeInfo.lepoInfo.length > 0 && (
                  <span className="subtitle divide">
                    {" "}
                    <span role="separator">
                      <span aria-hidden>&bull;&nbsp;</span>
                    </span>
                    {parse(data.programmeInfo.lepoInfo)}
                  </span>
                )}
              {data.eventDetailOverrides.authorAndDirector ? (
                <span className="divide">
                  {" "}
                  <span role="separator">
                    <span aria-hidden>&bull;&nbsp;</span>
                  </span>
                  {parse(data.eventDetailOverrides.authorAndDirector)}
                </span>
              ) : (
                <>
                  {writer && (
                    <span className="divide">
                      {" "}
                      <span role="separator">
                        <span aria-hidden>&bull;&nbsp;</span>
                      </span>
                      {parse(writer)}
                    </span>
                  )}
                  {!writer &&
                    !isNewFormat(data.eventDetails.startTime) &&
                    !data.eventDetails.eventDetailsWriter &&
                    (data.eventDetails.originalTextEn ||
                      data.eventDetails.originalTextDe) && (
                      <span className="divide">
                        {" "}
                        <span role="separator">
                          <span aria-hidden>&bull;&nbsp;</span>
                        </span>
                        <IntText
                          de={data.eventDetails.originalTextDe}
                          en={data.eventDetails.originalTextEn}
                          showOtherLang={true}
                        />
                      </span>
                    )}
                  {director && (
                    <span className="divide">
                      {" "}
                      <span role="separator">
                        <span aria-hidden>&bull;&nbsp;</span>
                      </span>
                      {parse(director)}
                    </span>
                  )}
                </>
              )}
              {data.eventDetails.stageId !== 163 &&
                data.eventDetails.surtitles &&
                !data.eventDetails.surtitlesEvent && (
                  <span className="subtitle divide">
                    {" "}
                    <span role="separator">
                      <span aria-hidden>&bull;&nbsp;</span>
                    </span>
                    <FormattedMessage
                      id={`surtitles.${
                        SurtitleVariants[data.eventDetails.surtitles]
                      }`}
                      defaultMessage={data.eventDetails.surtitles}
                    />
                  </span>
                )}
              {data.eventDetails.stageId !== 163 &&
                data.eventDetails.surtitlesEvent && (
                  <span className="subtitle divide">
                    {" "}
                    <span role="separator">
                      <span aria-hidden>&bull;&nbsp;</span>
                    </span>
                    <FormattedMessage
                      id={`surtitles.${
                        SurtitleVariants[data.eventDetails.surtitlesEvent]
                      }`}
                      defaultMessage={data.eventDetails.surtitlesEvent}
                    />
                  </span>
                )}
              {data.programmeInfo.eventCategory &&
                data.programmeInfo.eventCategory !==
                  data.eventDetails.subtitle &&
                data.programmeInfo.eventCategory.length > 0 && (
                  <span className="subtitle divide">
                    {" "}
                    <span role="separator">
                      <span aria-hidden>&bull;&nbsp;</span>
                    </span>
                    {parse(data.programmeInfo.eventCategory)}
                  </span>
                )}
              {data.eventDetails.eventType &&
                data.eventDetails.eventType !==
                  data.programmeInfo.eventCategory &&
                data.eventDetails.eventType.length > 0 && (
                  <span className="subtitle divide">
                    {" "}
                    <span role="separator">
                      <span aria-hidden>&bull;&nbsp;</span>
                    </span>
                    {parse(data.eventDetails.eventType)}
                  </span>
                )}
            </div>
          </Title>
        )}
        {/* <span>{parse(data.title)}</span> */}
        {/* <Title id={data.productionId} /> */}
        {/* {data[locale].title} */}
      </div>
      <span className="rightAligned">
        {data.eventDetails.customInfo &&
          (!ignoreSpecial.includes(data.eventDetails.customInfo) ||
            displayIgnoredSpecial.includes(data.eventDetails.customInfo)) && (
            <span className="specialInfo">
              {customInfoMapping[parse(data.eventDetails.customInfo)] ? (
                <FormattedMessage
                  id={`special.${
                    customInfoMapping[parse(data.eventDetails.customInfo)]
                  }`}
                  defaultMessage={parse(data.eventDetails.customInfo)}
                />
              ) : (
                parse(data.eventDetails.customInfo)
              )}
            </span>
          )}
        {data.event_cancellation && data.event_cancellation.cancelled && (
          <span className="specialInfo cancelled">
            <FormattedMessage
              id={`events.cancellation.${
                data.event_cancellation.cancellationMode
                  ? data.event_cancellation.cancellationMode
                  : "cancelled"
              }`}
              defaultMessage="abgesagt"
            />
            {data.event_cancellation.cancellationMode === "postponed" &&
              data.event_cancellation.ersatztermin && (
                <>
                  :&nbsp;
                  {moment(
                    data.event_cancellation.ersatztermin.eventDetails.startTime
                  ).format("DD.MM.")}
                </>
              )}
          </span>
        )}
        {!data.event_cancellation.cancelled &&
          data.eventim_import &&
          (data.eventim_data.eventimId ||
            data.eventim_data.eventimUrl ||
            data.eventim_data.soldOut) &&
          !data.eventim_data.noTicketLink &&
          moment().isBefore(moment(data.eventDetails.endTime)) && (
            <span className="ticketLink">
              {(data.eventim_import.eventimCapacity === null ||
                data.eventim_import.eventimStatus > 0 ||
                data.eventim_data.eventimUrl) &&
              !data.eventim_data.soldOut ? (
                <a
                  href={
                    data.eventim_data.eventimUrl ??
                    `https://ticket.volksbuehne-berlin.de/eventim.webshop/webticket/shop?event=${data.eventim_data.eventimId}`
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  <span>&gt;</span>
                  <FormattedMessage
                    id="tickets.link"
                    defaultMessage="Tickets"
                  />
                </a>
              ) : (
                <span>
                  x&nbsp;
                  <FormattedMessage
                    id="tickets.sold-out"
                    defaultMessage="Ausverkauft"
                  />
                </span>
              )}
            </span>
          )}
        {!data.event_cancellation.cancelled &&
          data.eventim_data.noTicketLink && (
            <span className="ticketLink">
              x&nbsp;
              <span className="tickets">
                <FormattedMessage
                  id="tickets.not-available"
                  defaultMessage="Tickets"
                />
              </span>
            </span>
          )}
      </span>
    </div>
  );
};

LepoItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export default LepoItem;
