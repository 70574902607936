import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Gallery from "../gallery/Gallery";
import { getVenueClass } from "../../i18n/route-utils";
import parse from "html-react-parser";
import EventCalendar from "../eventCalendar/EventCalendar";
import { headline } from "../../cache";
import Logos from "../logos/Logos";
import MediaCredits from "../atoms/mediaCredits/MediaCredits";
import Loader from "../atoms/loader/Loader";
import "../show/Show.scss";
import { GET_VENUE_PAGE } from "../../queries";
import SeoTags from "../atoms/seoTags/SeoTags";
import { HashLink } from "react-router-hash-link";

const VenuePage = ({
  title,
  venueId,
  venueIds,
  sortInto,
  search,
  eventCalendarType,
  showPremieres,
  showArchive,
  omitVenueInArchive,
  startDate,
  endDate,
}) => {
  const { locale } = useIntl();
  const description = useRef();
  const titleRef = useRef();
  const [titleHeight, setTitleHeight] = useState();

  const { data, loading, error } = useQuery(GET_VENUE_PAGE, {
    variables: { title },
  });

  useEffect(() => {
    headline("");
  }, []);

  useEffect(() => {
    if (data && titleRef.current) {
      const { offsetHeight } = titleRef.current;
      setTitleHeight(offsetHeight);
    }
  }, [titleRef, data]);

  if (error) {
    return <Redirect to={`/${locale}/404`} />;
  }

  if (loading) {
    return <Loader />;
  }

  if (data && data.venuePages.nodes[0]) {
    return (
      <>
        <SeoTags
          title={data.venuePages.nodes[0].titles.titleDe || title}
          titleEn={
            data.venuePages.nodes[0].titles.titleEn ||
            data.venuePages.nodes[0].titles.titleDe ||
            title
          }
          seo={data.venuePages.nodes[0].seo}
        />
        <nav className="screenreader">
          <HashLink to="#events">
            <FormattedMessage id="anchor.events" defaultMessage="Events" />
          </HashLink>
        </nav>
        <main
          className={`venuePage show ${getVenueClass(
            venueIds ? venueIds.substring(0, 3) : venueId
          )}`}
          key={title}
        >
          {(data.venuePages.nodes[0].gallery ||
            data.venuePages.nodes[0].productionVideos) && (
            <div className="topImage">
              <Gallery
                data={data.venuePages.nodes[0].gallery}
                videos={data.venuePages.nodes[0].productionVideos}
              />
            </div>
          )}
          <div className="titleSection" ref={titleRef}>
            <h1>
              {parse(
                locale === "de"
                  ? data.venuePages.nodes[0].titles.titleDe
                  : data.venuePages.nodes[0].titles.titleEn
              )}
            </h1>
          </div>
          <section className="mainContent">
            {data && (
              <div className="descriptionWrapper">
                <div ref={description} className="description">
                  {data.venuePages &&
                    data.venuePages.nodes &&
                    data.venuePages.nodes[0] &&
                    data.venuePages.nodes[0].text_content &&
                    data.venuePages.nodes[0].text_content.contentDe && (
                      <div>
                        {parse(
                          locale === "de"
                            ? data.venuePages.nodes[0].text_content.contentDe
                            : data.venuePages.nodes[0].text_content.contentEn ||
                                "English Version coming soon"
                        )}
                      </div>
                    )}
                </div>
                <Logos logos={data.venuePages.nodes[0].gallery.logos} />
                <MediaCredits
                  galleryImages={data.venuePages.nodes[0].gallery.gallery}
                  vidPosition={data.venuePages.nodes[0].gallery.vidPosition}
                  videoData={data.venuePages.nodes[0].productionVideos}
                />
              </div>
            )}
            <div
              className="calendarWrapper"
              id="events"
              style={{ top: `${titleHeight + 25}px` }}
            >
              <EventCalendar
                key={`normal-${venueIds ? venueIds : venueId}-${search}`}
                type={eventCalendarType}
                venueId={venueId}
                venueIds={venueIds}
                sortInto={sortInto}
                search={search}
                startDate={startDate}
                endDate={endDate}
              />
              {(showPremieres || showArchive) && (
                <EventCalendar
                  key={`archive-${venueId}-${search}`}
                  type={eventCalendarType}
                  venueId={venueId}
                  venueIds={venueIds}
                  sortInto={sortInto}
                  search={search}
                  startDate={startDate}
                  endDate={endDate}
                  withPremieres={showPremieres}
                  withArchive={showArchive}
                  omitVenueInArchive={omitVenueInArchive}
                  withSeparator
                />
              )}
            </div>
          </section>
        </main>
      </>
    );
  } else {
    return null;
  }
};

export default VenuePage;
