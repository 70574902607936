import React, { useEffect, useState } from "react";
import { showActive, zeroPad } from "../../utilities";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import {
  GET_EVENTS_OF_SHOW,
  GET_SHOW,
  GET_SHOW_BY_SLUG,
  GET_SINGLE_EVENT,
  GET_SINGLE_EVENT_BY_SLUG,
  GET_TIME_FRAME,
} from "../../queries";

const Title = ({ id, children, links, baseRoute }) => {
  const { formatMessage, locale } = useIntl();
  const [redirectTo, setRedirectTo] = useState();
  const [preloadFunction, setPreloadFunction] = useState();
  const { data: months } = useQuery(GET_TIME_FRAME);

  const { data: showData, client: showClient } = useQuery(GET_SHOW_BY_SLUG, {
    variables: { slug: links.productionLink },
    skip: !links.productionLink,
  });

  const { data: seData, client: SEClient } = useQuery(
    GET_SINGLE_EVENT_BY_SLUG,
    {
      variables: { slug: links.singleEventLink },
      skip: !links.singleEventLink,
    }
  );

  useEffect(() => {
    if (seData && showActive(seData.singleEvent)) {
      setPreloadFunction(preloadSingleEvent);
      setRedirectTo(
        `/${locale}/${formatMessage({ id: "route.events" })}/${
          links.singleEventLink
        }`
      );
    } else if (showData && showActive(showData.show)) {
      setPreloadFunction(preloadShow);
      const isSeries = !!(
        showData.show &&
        showData.show.productionDetails &&
        showData.show.productionDetails.isSeries
      );

      if (baseRoute) {
        setRedirectTo(`/${locale}/${baseRoute}/${links.productionLink}`);
      } else {
        setRedirectTo(
          `/${locale}/${formatMessage({
            id: isSeries ? "route.series" : "route.repertoire",
          })}/${links.productionLink}`
        );
      }
    }
  }, [seData, showData, locale, baseRoute]);

  const preloadShow = () => {
    showClient.query({
      query: GET_SHOW,
      variables: { slug: links.productionLink },
    });
    showClient.query({
      query: GET_EVENTS_OF_SHOW,
      variables: {
        productionId: String(id),
        start: `${months.timeFrame.start.year}${zeroPad(
          months.timeFrame.start.month,
          2
        )}00`,
        end: `${months.timeFrame.end.year}${zeroPad(
          months.timeFrame.end.month,
          2
        )}31`,
      },
    });
  };

  const preloadSingleEvent = () => {
    SEClient.query({
      query: GET_SINGLE_EVENT,
      variables: {
        slug: links.singleEventLink,
      },
    });
  };

  return redirectTo ? (
    <Link
      key={redirectTo}
      to={redirectTo}
      onMouseOver={preloadFunction}
      onFocus={preloadFunction}
      className="title underline"
    >
      {children}
      {/* {parse(title)} */}
    </Link>
  ) : (
    children
  );
};

export default React.memo(Title);
